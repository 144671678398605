import React, { useContext } from "react"
import Text from '@typo/Text'
import StyledLink from '@typo/StyledLink'
import MainHeading from '@typo/MainHeading'
import styled from 'styled-components'
import headerImage from '@images/articles/erotic-audio-stories/blog-header.svg'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "@components/Seo";
import FAQContent from '@components/schemas/seoEroticAudiobookStories.json'
import Header from "@menus/header"
import { FirebaseContext } from '@components/Firebase'

const ArticleWrapper = styled.div`
  display:flex;
  justify-content: center;
  padding-top:90px;
  @media (min-width: 768px) {
    width:100%;
    padding-top:150px;
  }
`

const ArticleHeaderImage =  styled.img`
/* padding-top:30px; */
`

const ContentPaddingWrapper = styled.div`
  max-width:900px;
  padding: 0px 5% 100px 5%;
`

const FirstText =  styled(Text)`
 &:first-letter {
    text-transform:${props => props.title ? '' : 'uppercase'};
    font-size:${props => props.title  ? '' : '200%'};
    font-family:${props => props.title ? '' : props.theme.mainFontHeading};
    line-height: 1;
  }
  margin-bottom:50px;

`

const MetaData =  styled.p`
  font-family:${props => props.title ? '' : props.theme.mainFontParagraph};
  font-size:0.8rem;
  line-height:0.5;
`
const ListItemWrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  padding-bottom:50px;
  align-items: center;

`

const ItemImageWrapper = styled.div`
  width:100%;
  @media (min-width: 768px) {
    width:100%;
  }
`

const ItemTextColumn = styled.div`

  
`
const ItemText = styled.div`
  font-family: ${props => props.theme.mainFontParagraph},'Arial';
  line-height: 1.6;
  h4 {
    margin: 0px;
    font-size: 1.4rem;
  }

  a {
    color: ${props => props.theme.secondaryColor};
  }
`

const ItemCta = styled(Text)`
  font-family:'Poppins';
`
const ItemMetaInfo = styled(Text)`
  line-height:1;
`
const SubHeadline = styled(Text)`
  padding-bottom:10px;
  font-style:italic;
`

const EroticAudio = styled.span`
  font-size:14px; 
  font-family:'Poppins';
  padding-left:3px;
  display: block;
`

const ItemHeadline = styled.h2`
  font-size: 2.5rem;
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
 
  }
`
const FAQItemHeadline = styled.h3`
  font-size: 2rem;
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
 
  }
`

const HeaderTitle = styled.h2`
  ${props => props.topPadding ? 'padding-top: 100px;' : ''}
  font-size: 3rem;
  ${props => props.center ? 'text-align:center;' : ''}
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
    text-align:center;
  }
`

const content = {
  "anal-adventure":{
    "subtitle":"Meditiation Erotic Audio:",
    "text":"This exciting audio sex story details your first time visiting your new boyfriend’s apartment. He tells you to make yourself comfortable as he gets you some drinks, and you can’t help but notice the drawer he’s left open. You eventually discover it’s a drawer full of sex toys - and there’s one that excites you the most...the butt plug. This 10-minute whirlwind adventure is sure to get you and your lover in the mood for a HOT AF anal adventure.",
    "rank":10
  },
  "strap-on-seduction":{
    "subtitle":"Public Sex Erotic Audio:",
    "text":"This audio porn story has you visiting Los Angeles for a work conference. Instead of checking into a hotel, you’re decided to couch-hop. Your best friend has an acquaintance that lives nearby your conference location, so they graciously offer you a place to stay. There’s just one little thing...it’s your best friend’s ex-girlfriend. While you think it may be a bit awkward knowing some of the most intimate details about this stranger, when the chemistry starts flowing, you can’t help but get lost in the attraction. This 18-minute LGBTQ+ audio story has everything you need - spontaneity, intimacy, and most importantly, passion.",
    "rank":9
  },
  "composure":{
    "subtitle":"Meditiation Erotic Audio:",
    "text":"Newlyweds Charlotte and Clive are wandering around the Contemporary Museum of Art, admiring the ancient Greek and Roman statues. Charlotte is having difficulty concentrating on the beauty of the art around her. Her thoughts are consumed by one thing: the remote control in Clive’s pocket. Today, at the insistence of her husband, Charlotte is wearing a small, vibrating egg inside of her. Will she be able to keep her composure when Clive starts to play with her? This exciting 16-minute audio story will have you and your lover itching to test out this kind of public play.",
    "rank":8
  },
  "massage-sex":{
    "subtitle":"Romantic Erotic Audio:",
    "text":"In this audio porn story, you indulge in a relaxing weekend at a luxurious hotel nestled in the Swiss Alps. You book yourself a massage and can’t help the physical attraction you share with the sexy masseur. The small towel is the only thing that covers your body and as he works to relax your muscles, all you can think about is what he tastes like. This 13-minute secret hook-up story is full of intense and erotic moments to get you into the mood.",
    "rank":7
  },
  "escoted-male-sex-story":{
    "subtitle":"Meditiation Erotic Audio:",
    "text":"You have booked yourself a little treat after a long, hard week of work. All you want is to unwind and lose yourself in someone else. That’s why you hired him - to take away your stress, to indulge you in an evening of compliments, sensual touches, and incredible sex. This 23-minute audio story is steamy, intense, erotic and so much fun.",
    "rank":6, 
  },
  "summer-rain-threesome":{
    "subtitle":"Stranger Erotic Audio:",
    "text":"This audio porn story takes place at an annual summer party. You and your guests are enjoying an afternoon in the garden, sharing drinks, food, and laughter, when all of a sudden a rainstorm approaches. While everyone else heads inside, you and two others get stranded under the gazebo. With no one around, things start to heat up and you indulge in one of your wildest fantasies - a threesome. This 14-minute session with multiple lovers is hot, hot, hot.",
    "rank":5
  },
  "nightclub-heat":{
    "subtitle":"Sex At Work Erotic Audio:",
    "text":"Speaking of “hot stories”, this nightclub hookup story really brings the heat. You’re at the club with some friends when you notice a stranger making eye contact with you. She seems totally hypnotized by everything you’re doing - the way you laugh, the way you dance, the way everyone around you seems so interested in anything you have to say. Soon, the attractive woman approaches you and things quickly heat up on the dance floor...then in the private restroom of the club. Things get wild with a beautiful stranger in this 20-minute LGBTQ+ audio sex story.",
    "rank":4
  },
  "accidental-voyeur":{
    "subtitle":"Friends To Lovers Erotic Audio:",
    "text":"You arrive in Montreal at the luxurious hotel your company is paying for. You check-in, have a hot shower, and unwind. You’re feeling stressed about your meetings tomorrow - things have to go well, your company is depending on you. To relax, you begin practicing some of your mindfulness techniques - breathing in...and out. In...and out. All of a sudden, you begin to overhear someone’s most intimate moments. They really do sound like they are having fun. You go over to the window to investigate - and that’s when you see them. The couple in the apartment building next door is (very intentionally) putting on a show...and it would be rude not to enjoy it. Listening to this 12-minute erotic masturbation audio story is every voyeur’s dream come true.",
    "rank":3
  },
  "blackout":{
    "subtitle":"In The Car Erotic Audio:",
    "text":"In this audio sex story, a college reunion brings together two ex-lovers who just happen to be single and in search of something intimate and exciting. You and your old friends gather at a cabin in the woods to laugh and reminisce about the “good old days.” It’s not long after arriving that the power goes out. While searching for a solution, your ex-boyfriend suggests you take this time to catch up in a more intimate way in the loft upstairs. This 16-minute ex-boyfriend hookup story is equal parts steamy and romantic, with a dash of nostalgia.",
    "rank":2
  },
  "ranch-hand":{
    "subtitle":"In Public Erotic Audio:",
    "text":"There are fifteen miles of dust and desert between him and his house after a long day of working hard out on the Agua Verde Ranch. He stumbles upon you and your broken down Sedan, stranded in the middle of nowhere. You have already called a tow truck - but when he offers to wait with you, you say you wouldn’t mind the company out here in the middle of nowhere while you wait for help. You can’t help but lust after his thick, Southern accent and the way his dirt-stained jeans hug his body. Things really heat up on Highway 59 in this explicit 21-minute cowboy/city girl fling.",
    "rank":1
  },

}


const articleSchema = {
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "headline": "Enjoy sensual relaxation with hot audio stories.",
  "image": [
    "https://audiodesires.com/images/erotic-audio-stories/top-10-hot-audio-stories-audiodesires-1-1.jpg",
    "https://audiodesires.com/images/erotic-audio-stories/top-10-hot-audio-stories-audiodesires-4-3.jpg",
    "https://audiodesires.com/images/erotic-audio-stories/top-10-hot-audio-stories-audiodesires-16-9.jpg"
   ],
  "datePublished": "2021-29-03",
  "dateModified": "2021-29-03",
  "isAccessibleForFree":true,
  "author": {
    "@type": "Person",
    "name": "Jaimee Bell"
  },
   "publisher": { 
    "@type": "Organization",
    "name": "Audiodesires",
    "logo": {
      "@type": "ImageObject",
      "url": "https://audiodesires.com/images/logos/audiodesires_black.png"
    }
  },
  "mainEntityOfPage":{
    "@type":"WebPage",
    "@id":"https://audiodesires.com/hot-audio-stories/"
 }
}

const EroticAudioBookStories = (props) => {
  const {user} = useContext(FirebaseContext);
  const lang = props.pageContext.lang

  const convertArrayToObject = (array, key, content) => {
    const initialValue = [];
    return array.reduce((obj, item) => {
      return [...obj,{...content[item.en[key]],...item}]
    }, initialValue);
  };
  const Stories =  convertArrayToObject(props.data.allStory.nodes, 'slug', content)

  return <>
  <Header boxLayout={false} playerMode={'small'} user={user} alwaysShow={true}/>
  <ArticleWrapper>
    <Seo 
      title={"Enjoy sensual relaxation with hot audio stories"}
      description={"Hot Audio Stories for women ✅ Professional audio erotica ✅ Different stories and fantasies ✅ 5-20 min ✅ Free stories available ✅ Respectful & relatable"}
      structuredData={[articleSchema, FAQContent]}
    />
    <ContentPaddingWrapper>
      
      <MainHeading>{articleSchema.headline}</MainHeading>
      <SubHeadline>Indulge in your kinky fantasies with these hot AF stories.</SubHeadline>
      <MetaData>Last Updated on March 29, 2021 </MetaData>
      <MetaData>by {articleSchema.author.name} </MetaData>
      <ArticleHeaderImage src={headerImage} alt="Hot Audio Stories by Audiodesires"/>
      <FirstText>
        Last year was wild. I mean, it was “would-not-believe-it-if-we-hadn’t-lived-it” wild. Ringing in the new year and welcoming 2021 had us all wondering what this next year could possibly bring. While I can’t tell you how 2021 will end, I know how you can enjoy it from the comfort of your own home...by listening to these HOT AF audio stories. Guided masturbation tracks, LGBTQ+ stories, and our first ever erotic audio artist collaborations...there’s so much to enjoy while laying in bed (by yourself or with your lover) in 2021.
        <br/><br/>
        Audiodesires is a platform that offers hot audio clips for women and couples to explore either together or separately. This year we have some new exciting things on the horizon. We can offer you a whole new way to explore new kinks, sexual adventures, and each other. 
        <br/><br/>
        Below are the top 10 hot audio stories you absolutely need to listen to this year. These rankings are created based on ratings they’ve been given by listeners just like you. 
        <br/><br/>
        Hey, you’re staying home and staying safe, anyways - right? You might as well put on some headphones and indulge. 
      </FirstText>
      {Stories.sort((a,b) =>  b.rank - a.rank ).map((story) => (
        <ListItemWrapper key={story.rank}>
          
          <ItemImageWrapper>
            <Link to={`/story/${story.en.slug}`}>
              <GatsbyImage
                image={story.localImage.childImageSharp.gatsbyImageData}
                alt={`${story.en.title} - Erotic Audio Story by Audiodesires`} />
            </Link>
          </ItemImageWrapper>
          <ItemTextColumn>
            <ItemHeadline><EroticAudio>{story.subtitle}</EroticAudio>{story.rank}. {story.en.title}</ItemHeadline>
            <ItemMetaInfo>Narrator: <StyledLink to={`/voice/${story[lang].voices[0].name.toLowerCase()}`}>{story[lang].voices[0].name}</StyledLink></ItemMetaInfo>
            <ItemText dangerouslySetInnerHTML={{ __html: content[story.en.slug].text}}/>
            <ItemCta>Listen to <StyledLink to={`/story/${story.en.slug}`}>{story.en.title}</StyledLink>{} now.</ItemCta>
          </ItemTextColumn>
        </ListItemWrapper>
      
      ))}
      <HeaderTitle center >Frequently Asked Questions (FAQ) about Hot Audio Stories</HeaderTitle>
      {FAQContent.mainEntity.map((edge,index) =>(
        <ListItemWrapper key={index}>
          <ItemTextColumn>
          <FAQItemHeadline>{edge.name}</FAQItemHeadline>
          <ItemText dangerouslySetInnerHTML={{ __html: edge.acceptedAnswer.text}}/>
          </ItemTextColumn>
        </ListItemWrapper>
      ))}
    </ContentPaddingWrapper>
  </ArticleWrapper>
</>;
}

export default EroticAudioBookStories
export const query = graphql`{
  allStory(
    filter: {en: {slug: {in: ["anal-adventure", "strap-on-seduction", "composure", "massage-sex", "escoted-male-sex-story", "summer-rain-threesome", "nightclub-heat", "accidental-voyeur", "blackout", "ranch-hand"]}}}
  ) {
    nodes {
      ...seoBlogPostStoryInfo 
    } 
  }
}
`